import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'

const _25f5672a = () => interopDefault(import('../pages/articeListMsg/index.vue' /* webpackChunkName: "pages/articeListMsg/index" */))
const _05845622 = () => interopDefault(import('../pages/articelList.vue' /* webpackChunkName: "pages/articelList" */))
const _63d09d4a = () => interopDefault(import('../pages/articelList.vue/index.vue' /* webpackChunkName: "pages/articelList.vue/index" */))
const _3a5577e0 = () => interopDefault(import('../pages/bookDetail/index.vue' /* webpackChunkName: "pages/bookDetail/index" */))
const _8c1204a2 = () => interopDefault(import('../pages/bookPreview/index.vue' /* webpackChunkName: "pages/bookPreview/index" */))
const _73a24b52 = () => interopDefault(import('../pages/brand/index.vue' /* webpackChunkName: "pages/brand/index" */))
const _accf0bde = () => interopDefault(import('../pages/buyCourse/index.vue' /* webpackChunkName: "pages/buyCourse/index" */))
const _969381f0 = () => interopDefault(import('../pages/class/index.vue' /* webpackChunkName: "pages/class/index" */))
const _47b88584 = () => interopDefault(import('../pages/columnist/index.vue' /* webpackChunkName: "pages/columnist/index" */))
const _5f179dfa = () => interopDefault(import('../pages/consultList/index.vue' /* webpackChunkName: "pages/consultList/index" */))
const _5fc78777 = () => interopDefault(import('../pages/courseDetails/index.vue' /* webpackChunkName: "pages/courseDetails/index" */))
const _fc2b317c = () => interopDefault(import('../pages/courseEmpty/index.vue' /* webpackChunkName: "pages/courseEmpty/index" */))
const _492efb74 = () => interopDefault(import('../pages/detailPage/index.vue' /* webpackChunkName: "pages/detailPage/index" */))
const _1be8310e = () => interopDefault(import('../pages/download/index.vue' /* webpackChunkName: "pages/download/index" */))
const _dd479f2a = () => interopDefault(import('../pages/ebooks/index.vue' /* webpackChunkName: "pages/ebooks/index" */))
const _ec9e63d8 = () => interopDefault(import('../pages/epidemicInformation/index.vue' /* webpackChunkName: "pages/epidemicInformation/index" */))
const _2dc488e8 = () => interopDefault(import('../pages/guide/index.vue' /* webpackChunkName: "pages/guide/index" */))
const _1b6059f2 = () => interopDefault(import('../pages/live/index.vue' /* webpackChunkName: "pages/live/index" */))
const _1ea8f03a = () => interopDefault(import('../pages/liveDetail/index.vue' /* webpackChunkName: "pages/liveDetail/index" */))
const _78517f84 = () => interopDefault(import('../pages/newClass/index.vue' /* webpackChunkName: "pages/newClass/index" */))
const _ddf2a26a = () => interopDefault(import('../pages/novelCoronavirus/index.vue' /* webpackChunkName: "pages/novelCoronavirus/index" */))
const _746b15fc = () => interopDefault(import('../pages/novelCoronavirusDetail/index.vue' /* webpackChunkName: "pages/novelCoronavirusDetail/index" */))
const _3b50bfc0 = () => interopDefault(import('../pages/payDetails/index.vue' /* webpackChunkName: "pages/payDetails/index" */))
const _565b9041 = () => interopDefault(import('../pages/paySuccess/index.vue' /* webpackChunkName: "pages/paySuccess/index" */))
const _7a207624 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _5ca3c10f = () => interopDefault(import('../pages/self.vue' /* webpackChunkName: "pages/self" */))
const _534dff5c = () => interopDefault(import('../pages/self/index.vue' /* webpackChunkName: "pages/self/index" */))
const _9f4b7d50 = () => interopDefault(import('../pages/self/auth.vue' /* webpackChunkName: "pages/self/auth" */))
const _02aaaf5e = () => interopDefault(import('../pages/self/myCollect.vue' /* webpackChunkName: "pages/self/myCollect" */))
const _ff2f6dd2 = () => interopDefault(import('../pages/self/myCourse.vue' /* webpackChunkName: "pages/self/myCourse" */))
const _61a6cafc = () => interopDefault(import('../pages/self/myOrder.vue' /* webpackChunkName: "pages/self/myOrder" */))
const _219cb73d = () => interopDefault(import('../pages/self/pwd.vue' /* webpackChunkName: "pages/self/pwd" */))
const _5a39a06e = () => interopDefault(import('../pages/special/index.vue' /* webpackChunkName: "pages/special/index" */))
const _0d001020 = () => interopDefault(import('../pages/survey/index.vue' /* webpackChunkName: "pages/survey/index" */))
const _f7da974e = () => interopDefault(import('../pages/tagPage/index.vue' /* webpackChunkName: "pages/tagPage/index" */))
const _a3f5d460 = () => interopDefault(import('../pages/about/aboutMe.vue' /* webpackChunkName: "pages/about/aboutMe" */))
const _7cab366b = () => interopDefault(import('../pages/about/contact.vue' /* webpackChunkName: "pages/about/contact" */))
const _58517c5a = () => interopDefault(import('../pages/about/privacy.vue' /* webpackChunkName: "pages/about/privacy" */))
const _f074bddc = () => interopDefault(import('../pages/about/terms.vue' /* webpackChunkName: "pages/about/terms" */))
const _cad15e48 = () => interopDefault(import('../pages/details/live.vue' /* webpackChunkName: "pages/details/live" */))
const _637b8d8a = () => interopDefault(import('../pages/details/video.vue' /* webpackChunkName: "pages/details/video" */))
const _3f03e70c = () => interopDefault(import('../pages/ebooks/bookshelf.vue' /* webpackChunkName: "pages/ebooks/bookshelf" */))
const _6dad71e2 = () => interopDefault(import('../pages/guide/constitutorFilter.vue' /* webpackChunkName: "pages/guide/constitutorFilter" */))
const _e350e708 = () => interopDefault(import('../pages/guide/details.vue' /* webpackChunkName: "pages/guide/details" */))
const _a15d8d46 = () => interopDefault(import('../pages/guide/filterPage.vue' /* webpackChunkName: "pages/guide/filterPage" */))
const _4e0404a9 = () => interopDefault(import('../pages/guide/searcPage.vue' /* webpackChunkName: "pages/guide/searcPage" */))
const _3a1d8b16 = () => interopDefault(import('../pages/bookDetail/components/directory.vue' /* webpackChunkName: "pages/bookDetail/components/directory" */))
const _08798c6e = () => interopDefault(import('../pages/bookDetail/components/directoryItem.vue' /* webpackChunkName: "pages/bookDetail/components/directoryItem" */))
const _2eb655a4 = () => interopDefault(import('../pages/bookDetail/components/fontSizeProgress.vue' /* webpackChunkName: "pages/bookDetail/components/fontSizeProgress" */))
const _a1cb82e2 = () => interopDefault(import('../pages/bookDetail/components/progressBar.vue' /* webpackChunkName: "pages/bookDetail/components/progressBar" */))
const _3552651d = () => interopDefault(import('../pages/courseDetails/components/coueseCatalog.vue' /* webpackChunkName: "pages/courseDetails/components/coueseCatalog" */))
const _66ff9d76 = () => interopDefault(import('../pages/courseDetails/components/courseComment.vue' /* webpackChunkName: "pages/courseDetails/components/courseComment" */))
const _56c6d476 = () => interopDefault(import('../pages/courseDetails/components/courseIntroduce.vue' /* webpackChunkName: "pages/courseDetails/components/courseIntroduce" */))
const _b61f7868 = () => interopDefault(import('../pages/courseDetails/components/courseQuesstion.vue' /* webpackChunkName: "pages/courseDetails/components/courseQuesstion" */))
const _11d301d3 = () => interopDefault(import('../pages/courseDetails/components/replyComment.vue' /* webpackChunkName: "pages/courseDetails/components/replyComment" */))
const _34b6889f = () => interopDefault(import('../pages/courseDetails/components/replyOthers.vue' /* webpackChunkName: "pages/courseDetails/components/replyOthers" */))
const _296373cd = () => interopDefault(import('../pages/ebooks/components/bookClass.vue' /* webpackChunkName: "pages/ebooks/components/bookClass" */))
const _7905ecce = () => interopDefault(import('../pages/ebooks/components/bookItem.vue' /* webpackChunkName: "pages/ebooks/components/bookItem" */))
const _50fc618c = () => interopDefault(import('../pages/ebooks/components/bookPopover.vue' /* webpackChunkName: "pages/ebooks/components/bookPopover" */))
const _691ee3c8 = () => interopDefault(import('../pages/ebooks/components/myBooks.vue' /* webpackChunkName: "pages/ebooks/components/myBooks" */))
const _6c45d044 = () => interopDefault(import('../pages/search/components/articleItem.vue' /* webpackChunkName: "pages/search/components/articleItem" */))
const _7be43fde = () => interopDefault(import('../pages/search/components/bookItem.vue' /* webpackChunkName: "pages/search/components/bookItem" */))
const _6f4f3003 = () => interopDefault(import('../pages/search/components/courseItem.vue' /* webpackChunkName: "pages/search/components/courseItem" */))
const _023f85ec = () => interopDefault(import('../pages/search/components/guideItem.vue' /* webpackChunkName: "pages/search/components/guideItem" */))
const _4d599d76 = () => interopDefault(import('../pages/search/components/mainSearch.vue' /* webpackChunkName: "pages/search/components/mainSearch" */))
const _9a97e94c = () => interopDefault(import('../pages/search/components/searchResult.vue' /* webpackChunkName: "pages/search/components/searchResult" */))
const _34eb092b = () => interopDefault(import('../pages/search/components/searchTitle.vue' /* webpackChunkName: "pages/search/components/searchTitle" */))
const _2e027b0c = () => interopDefault(import('../pages/search/components/subjectItem.vue' /* webpackChunkName: "pages/search/components/subjectItem" */))
const _421a2c90 = () => interopDefault(import('../pages/survey/components/newsList.vue' /* webpackChunkName: "pages/survey/components/newsList" */))
const _6f1e029f = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

const scrollBehavior = function(to, from, savedPosition) {
            return {
                x: 0,
                y: 0,
            }
        }

export function createRouter() {
  return new Router({
    mode: 'history',
    base: decodeURI('/'),
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,

    routes: [{
      path: "/articeListMsg",
      component: _25f5672a,
      name: "articeListMsg"
    }, {
      path: "/articelList",
      component: _05845622,
      name: "articelList"
    }, {
      path: "/articelList.vue",
      component: _63d09d4a,
      name: "articelList.vue"
    }, {
      path: "/bookDetail",
      component: _3a5577e0,
      name: "bookDetail"
    }, {
      path: "/bookPreview",
      component: _8c1204a2,
      name: "bookPreview"
    }, {
      path: "/brand",
      component: _73a24b52,
      name: "brand"
    }, {
      path: "/buyCourse",
      component: _accf0bde,
      name: "buyCourse"
    }, {
      path: "/class",
      component: _969381f0,
      name: "class"
    }, {
      path: "/columnist",
      component: _47b88584,
      name: "columnist"
    }, {
      path: "/consultList",
      component: _5f179dfa,
      name: "consultList"
    }, {
      path: "/courseDetails",
      component: _5fc78777,
      name: "courseDetails"
    }, {
      path: "/courseEmpty",
      component: _fc2b317c,
      name: "courseEmpty"
    }, {
      path: "/detailPage",
      component: _492efb74,
      name: "detailPage"
    }, {
      path: "/download",
      component: _1be8310e,
      name: "download"
    }, {
      path: "/ebooks",
      component: _dd479f2a,
      name: "ebooks"
    }, {
      path: "/epidemicInformation",
      component: _ec9e63d8,
      name: "epidemicInformation"
    }, {
      path: "/guide",
      component: _2dc488e8,
      name: "guide"
    }, {
      path: "/live",
      component: _1b6059f2,
      name: "live"
    }, {
      path: "/liveDetail",
      component: _1ea8f03a,
      name: "liveDetail"
    }, {
      path: "/newClass",
      component: _78517f84,
      name: "newClass"
    }, {
      path: "/novelCoronavirus",
      component: _ddf2a26a,
      name: "novelCoronavirus"
    }, {
      path: "/novelCoronavirusDetail",
      component: _746b15fc,
      name: "novelCoronavirusDetail"
    }, {
      path: "/payDetails",
      component: _3b50bfc0,
      name: "payDetails"
    }, {
      path: "/paySuccess",
      component: _565b9041,
      name: "paySuccess"
    }, {
      path: "/search",
      component: _7a207624,
      name: "search"
    }, {
      path: "/self",
      component: _5ca3c10f,
      children: [{
        path: "",
        component: _534dff5c,
        name: "self"
      }, {
        path: "auth",
        component: _9f4b7d50,
        name: "self-auth"
      }, {
        path: "myCollect",
        component: _02aaaf5e,
        name: "self-myCollect"
      }, {
        path: "myCourse",
        component: _ff2f6dd2,
        name: "self-myCourse"
      }, {
        path: "myOrder",
        component: _61a6cafc,
        name: "self-myOrder"
      }, {
        path: "pwd",
        component: _219cb73d,
        name: "self-pwd"
      }]
    }, {
      path: "/special",
      component: _5a39a06e,
      name: "special"
    }, {
      path: "/survey",
      component: _0d001020,
      name: "survey"
    }, {
      path: "/tagPage",
      component: _f7da974e,
      name: "tagPage"
    }, {
      path: "/about/aboutMe",
      component: _a3f5d460,
      name: "about-aboutMe"
    }, {
      path: "/about/contact",
      component: _7cab366b,
      name: "about-contact"
    }, {
      path: "/about/privacy",
      component: _58517c5a,
      name: "about-privacy"
    }, {
      path: "/about/terms",
      component: _f074bddc,
      name: "about-terms"
    }, {
      path: "/details/live",
      component: _cad15e48,
      name: "details-live"
    }, {
      path: "/details/video",
      component: _637b8d8a,
      name: "details-video"
    }, {
      path: "/ebooks/bookshelf",
      component: _3f03e70c,
      name: "ebooks-bookshelf"
    }, {
      path: "/guide/constitutorFilter",
      component: _6dad71e2,
      name: "guide-constitutorFilter"
    }, {
      path: "/guide/details",
      component: _e350e708,
      name: "guide-details"
    }, {
      path: "/guide/filterPage",
      component: _a15d8d46,
      name: "guide-filterPage"
    }, {
      path: "/guide/searcPage",
      component: _4e0404a9,
      name: "guide-searcPage"
    }, {
      path: "/bookDetail/components/directory",
      component: _3a1d8b16,
      name: "bookDetail-components-directory"
    }, {
      path: "/bookDetail/components/directoryItem",
      component: _08798c6e,
      name: "bookDetail-components-directoryItem"
    }, {
      path: "/bookDetail/components/fontSizeProgress",
      component: _2eb655a4,
      name: "bookDetail-components-fontSizeProgress"
    }, {
      path: "/bookDetail/components/progressBar",
      component: _a1cb82e2,
      name: "bookDetail-components-progressBar"
    }, {
      path: "/courseDetails/components/coueseCatalog",
      component: _3552651d,
      name: "courseDetails-components-coueseCatalog"
    }, {
      path: "/courseDetails/components/courseComment",
      component: _66ff9d76,
      name: "courseDetails-components-courseComment"
    }, {
      path: "/courseDetails/components/courseIntroduce",
      component: _56c6d476,
      name: "courseDetails-components-courseIntroduce"
    }, {
      path: "/courseDetails/components/courseQuesstion",
      component: _b61f7868,
      name: "courseDetails-components-courseQuesstion"
    }, {
      path: "/courseDetails/components/replyComment",
      component: _11d301d3,
      name: "courseDetails-components-replyComment"
    }, {
      path: "/courseDetails/components/replyOthers",
      component: _34b6889f,
      name: "courseDetails-components-replyOthers"
    }, {
      path: "/ebooks/components/bookClass",
      component: _296373cd,
      name: "ebooks-components-bookClass"
    }, {
      path: "/ebooks/components/bookItem",
      component: _7905ecce,
      name: "ebooks-components-bookItem"
    }, {
      path: "/ebooks/components/bookPopover",
      component: _50fc618c,
      name: "ebooks-components-bookPopover"
    }, {
      path: "/ebooks/components/myBooks",
      component: _691ee3c8,
      name: "ebooks-components-myBooks"
    }, {
      path: "/search/components/articleItem",
      component: _6c45d044,
      name: "search-components-articleItem"
    }, {
      path: "/search/components/bookItem",
      component: _7be43fde,
      name: "search-components-bookItem"
    }, {
      path: "/search/components/courseItem",
      component: _6f4f3003,
      name: "search-components-courseItem"
    }, {
      path: "/search/components/guideItem",
      component: _023f85ec,
      name: "search-components-guideItem"
    }, {
      path: "/search/components/mainSearch",
      component: _4d599d76,
      name: "search-components-mainSearch"
    }, {
      path: "/search/components/searchResult",
      component: _9a97e94c,
      name: "search-components-searchResult"
    }, {
      path: "/search/components/searchTitle",
      component: _34eb092b,
      name: "search-components-searchTitle"
    }, {
      path: "/search/components/subjectItem",
      component: _2e027b0c,
      name: "search-components-subjectItem"
    }, {
      path: "/survey/components/newsList",
      component: _421a2c90,
      name: "survey-components-newsList"
    }, {
      path: "/",
      component: _6f1e029f,
      name: "index"
    }],

    fallback: false
  })
}
